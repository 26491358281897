/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import Gallery from "react-grid-gallery";
import ModalImage from "react-modal-image";
import { Twemoji } from "react-emoji-render";
import ReactAudioPlayer from "react-audio-player";
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'react-linkify';

export const IncomingMessage = ({
  handler,
  message: { content, created_at, attachments, sender_name, message_type },
  message,
  isWidget,
  position,
  toggleContactInfo,
  locale
}) => {
  let imgFiles = [];
  let otherFiles = [];
  let hasFile = false;

  if (attachments.length) {
    hasFile = true;
    attachments.map((file) => {
      if (file.type.split("/")[0] == "image") {
        imgFiles.push({
          src: file.url,
          thumbnail: file.url,
          thumbnailWidth: 300,
          thumbnailHeight: 212,
          thumbnailCaption: content,
        });
      } else {
        otherFiles.push(file);
      }
    });
  }

  const date = new Date(message.created_at); // Replace this with your actual date, e.g., created_at

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    // timeZone: "Asian/Singapore",
    locale,
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  const formattedDate = formatter.format(date);

  const messageDisplay = ({ content }) => {
    const processMessage = (text) => {
      // Use react-html-parser to parse the HTML content
      const parsedContent = ReactHtmlParser(text, {
        // Add your custom parsing options if needed
      });
      // Wrap the parsed content with Linkify to automatically linkify URLs
      return (
        <span className="text-content-message">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {parsedContent}
          </Linkify>
        </span>
      );
    };
    const processedMessage = processMessage(content);
    return <div>{processedMessage}</div>;
  };

  return (
    <div className="row-fluid" id={message.id}>
      <div className="col-12 message">
        <div className="received_message">
          <div
            className={
              message_type === "call"
                ? "content-message log"
                : "content-message"
            }
          >
            {position.first && !isWidget ? (
              <span className="mb-2 d-block font-weight-bold" role="button" onClick={() => toggleContactInfo()}>
                {sender_name}
              </span>
            ) : (
              ""
            )}
            {!isWidget && (
              <>
                <input className="reply-btn" />
                <div className="reply-menu">
                  <span
                    type="button"
                    className="reply-menu-item"
                    onClick={() => {
                      handler(message);
                      document.getElementById("conversation-textarea").focus();
                    }}
                  >
                    {" "}
                    Reply
                  </span>
                </div>
              </>
            )}
            {message.quoted_message_id ? (
              <a href={`?tab=all&by_id=${message.conversation_id}&message_id=${message.quoted_message_id}&on_page=${message.quoted_message.on_page}`} style={{textDecoration: 'none'}}>
                <div className="reply-container">
                  <p className="reply-title">
                    {" "}
                    {message.quoted_message.status == "received"
                      ? message.quoted_message.name
                      : "You"}{" "}
                  </p>
                  <p className="reply-text">{message.quoted_message.content} </p>
                </div>
              </a>
            ) : null}
            {message.content && messageDisplay({ content: message.content })}

            {hasFile &&
              (imgFiles.length + otherFiles.length === 1 ? (
                <span
                  className={`file-attachment-message-preview ${
                    !imgFiles[0] ? "file-border" : ""
                  }`}
                >
                  {imgFiles.length === 1 ? (
                    <ModalImage
                      className="img-preview-file-attached"
                      small={imgFiles[0].src}
                      medium={imgFiles[0].src}
                      large={imgFiles[0].src}
                      alt={message.content}
                      hideZoom
                      showRotate={false}
                    />
                  ) : (
                    (() => {
                      if (otherFiles[0].type.split("/")[0] === "audio") {
                        return (
                          <div>
                            <ReactAudioPlayer
                              src={otherFiles[0].url}
                              controls
                            />
                          </div>
                        );
                      }
                      if (otherFiles[0].type.split("/")[0] === "video") {
                        return (
                          <div>
                            <video width="100%" controls>
                              <source src={otherFiles[0].url} type="video/mp4"/>
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        );
                      }
                      if (otherFiles[0].type.split("/")[0] === "sticker") {
                        return (
                          <a
                            target="_blank"
                            href={otherFiles[0].url}
                            rel="noreferrer"
                          >
                            Sticker &gt;
                          </a>
                        );
                      }
                      return (
                        <a
                          target="_blank"
                          href={otherFiles[0].url}
                          rel="noreferrer"
                        >
                          Show file attachment &gt;
                        </a>
                      );
                    })()
                  )}
                </span>
              ) : (
                <div className="file-attachments-wrap">
                  <div>
                    {imgFiles.length > 0 && <Gallery images={imgFiles} />}
                  </div>
                  <div className="file-attachements-non-image">
                    {otherFiles.length > 0 &&
                      otherFiles.map((file, i) => (
                        <div key={i}>
                          {(() => {
                            if (file.type.split("/")[0] === "audio") {
                              return (
                                <ReactAudioPlayer src={file.url} controls />
                              );
                            }
                            if (file.type.split("/")[0] === "video") {
                              return (
                                <div>
                                  <video width="100%" controls>
                                    <source src={file.url} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                              );
                            }
                            return (
                              <a
                                target="_blank"
                                href={file.url}
                                rel="noreferrer"
                              >
                                Show file attachment &gt;
                              </a>
                            );
                          })()}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>

          <span className="message-datetime-incoming">{formattedDate}</span>
        </div>
      </div>
    </div>
  );
};

IncomingMessage.defaultProps = {
  message: PropTypes.shape({
    content: "",
    created_at: "",
  }),
};

IncomingMessage.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string,
    created_at: PropTypes.string,
  }),
};

export default IncomingMessage;
